.app-loading {
  z-index: -1;

  .logo {
    width: 100px;
    height: 100px;

    // this way asset gets processed by webpack
    background: url(/assets/icon/favicon.png) center center no-repeat;
  }
}
