// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --base-width: 375;
  --width-ratio: calc(100vw / var(--base-width));

  --ion-font-family: "Roboto", -apple-system, BlinkMacSystemFont,
    "Helvetica Neue", sans-serif;

  // env(safe-area-inset-bottom) is 34px;
  // 覆寫, 以支援 custom tabbar
  --ion-safe-area-bottom: calc(max(env(safe-area-inset-bottom), 20px) - 20px);

  --safe-area-inset-bottom: env(
    safe-area-inset-bottom
  ); // 設置這個變數 讓javascript能夠抓取
  --safe-area-inset-top: env(
    safe-area-inset-top
  ); // 設置這個變數 讓javascript能夠抓取

  /** primary **/
  // --ion-color-primary: #726e94;
  // --ion-color-primary-rgb: 114, 110, 148;
  // --ion-color-primary-contrast: #ffffff;
  // --ion-color-primary-contrast-rgb: 255, 255, 255;
  // --ion-color-primary-shade: #543f7c;
  // --ion-color-primary-tint: #a292c1;
  // --ion-color-primary-tint-rgb: 162, 146, 193;
  --ion-color-primary: #b4a362;
  --ion-color-primary-rgb: 252, 163, 77;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #937a3d;
  --ion-color-primary-tint: #cdbc93;
  --ion-color-primary-tint-rgb: 205, 188, 147;

  /** secondary **/
  --ion-color-secondary: #b4a362;
  --ion-color-secondary-rgb: 180, 163, 98;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #937a3d;
  --ion-color-secondary-tint: #cdbc93;

  /** tertiary **/
  --ion-color-tertiary: #bcc4d5;
  --ion-color-tertiary-rgb: 188, 196, 213;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #a1aecb;
  --ion-color-tertiary-tint: #bcc4d5 20%;
  --ion-color-tertiary-tint-rgb: 188, 196, 213;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #f6cc74;
  --ion-color-warning-rgb: 246, 204, 116;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #f67c0a;
  --ion-color-warning-tint: #fbf8d4;

  /** danger **/
  --ion-color-danger: #eb4b55;
  --ion-color-danger-rgb: 235, 75, 85;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d42f39;
  --ion-color-danger-tint: #ea8074;
  --ion-color-danger-tint-rgb: 234, 128, 116;

  /** dark **/
  --ion-color-dark: #36363a;
  --ion-color-dark-rgb: 54, 54, 58;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #141010;
  --ion-color-dark-tint: #595959;

  /** medium **/
  --ion-color-medium: #8c8b8d;
  --ion-color-medium-rgb: 140, 139, 141;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #78747b;
  --ion-color-medium-tint: #b2b2b7;

  /** light **/
  --ion-color-light: #dcdddd;
  --ion-color-light-rgb: 220, 221, 221;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #cbcbcb;
  --ion-color-light-tint: #e9e9e9;
  --ion-color-light-tint-rgb: 229, 229, 229;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /* overwrite ionic 定義 */
  --ion-grid-padding: 0;
  --ion-grid-padding-xs: 0;
  --ion-grid-padding-sm: 0;
  --ion-grid-padding-md: 0;
  --ion-grid-padding-lg: 0;
  --ion-grid-padding-xl: 0;
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #428cff;
//     --ion-color-primary-rgb: 66, 140, 255;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255, 255, 255;
//     --ion-color-primary-shade: #3a7be0;
//     --ion-color-primary-tint: #5598ff;

//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80, 200, 255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255, 255, 255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;

//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106, 100, 255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47, 223, 117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0, 0, 0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255, 213, 52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0, 0, 0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255, 73, 97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255, 255, 255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244, 245, 248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0, 0, 0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152, 154, 162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0, 0, 0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34, 36, 40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255, 255, 255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0, 0, 0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }

//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18, 18, 18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

ion-item {
  --padding-start: 0px;
  --inner-padding-end: 0px;
}

@media (min-width: 768px) {
  :root {
    --base-width: 768;
    --width-ratio: calc(100vw / var(--base-width));
  }
}

@media (min-width: 1024px) {
  :root {
    --base-width: 1024;
    --width-ratio: calc(100vw / var(--base-width));
  }
}

@media (min-width: 1280px) {
  :root {
    --base-width: 1280;
    --width-ratio: calc(100vw / var(--base-width));
  }
}
