/******************************************************************************/
/*  這邊主要擺放有動態計算px的class, 未來也可能會使用 media query 來區別 */
/******************************************************************************/
@font-face {
  font-family: "Roboto"; //This is what we are going to call
  src: url("./assets/font/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Noto Sans"; //This is what we are going to call
  src: url("./assets/font/NotoSans-Regular.ttf");
}

.H2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: calc(24 / var(--base-width) * 100vw);
  line-height: calc(26 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.H2_M {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: calc(24 / var(--base-width) * 100vw);
  line-height: calc(26 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.H2_B {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: calc(24 / var(--base-width) * 100vw);
  line-height: calc(26 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.H3_B {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: calc(22 / var(--base-width) * 100vw);
  line-height: calc(26 / var(--base-width) * 100vw);
  color: var(--ion-color-dark-tint);
}

.H3_M {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: calc(22 / var(--base-width) * 100vw);
  line-height: calc(26 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.H4_B {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: calc(20 / var(--base-width) * 100vw);
  line-height: calc(26 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.H4_M {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: calc(20 / var(--base-width) * 100vw);
  line-height: calc(26 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.H5 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: calc(18 / var(--base-width) * 100vw);
  line-height: calc(26 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.H5_B {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: calc(18 / var(--base-width) * 100vw);
  line-height: calc(26 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.H5_M {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: calc(18 / var(--base-width) * 100vw);
  line-height: calc(26 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.H6 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: calc(16 / var(--base-width) * 100vw);
  line-height: calc(28 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.H6_B {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: calc(16 / var(--base-width) * 100vw);
  line-height: calc(26 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.H6_M {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: calc(16 / var(--base-width) * 100vw);
  line-height: calc(26 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.Subtitle1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: calc(15 / var(--base-width) * 100vw);
  line-height: calc(22 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.Subtitle1_B {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: calc(15 / var(--base-width) * 100vw);
  line-height: calc(22 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.Subtitle1_M {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: calc(15 / var(--base-width) * 100vw);
  line-height: calc(22 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.Subtitle2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: calc(14 / var(--base-width) * 100vw);
  line-height: calc(22 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.Subtitle2_B {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: calc(14 / var(--base-width) * 100vw);
  line-height: calc(22 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.Subtitle2_M {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: calc(14 / var(--base-width) * 100vw);
  line-height: calc(22 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.Caption {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: calc(12 / var(--base-width) * 100vw);
  line-height: calc(16 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.Caption_B {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: calc(12 / var(--base-width) * 100vw);
  line-height: calc(16 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.Caption_M {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: calc(12 / var(--base-width) * 100vw);
  line-height: calc(16 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.Overline {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: calc(10 / var(--base-width) * 100vw);
  line-height: calc(10 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

.Overline_B {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: calc(10 / var(--base-width) * 100vw);
  line-height: calc(10 / var(--base-width) * 100vw);
  color: var(--ion-color-dark);
}

/******************************************************************************/
/*  Padding , Margin class */
/******************************************************************************/
.mt-100d {
  margin-top: calc(100 / var(--base-width) * 100vw);
}

.mb-100d {
  margin-bottom: calc(100 / var(--base-width) * 100vw);
}

.mt-60d {
  margin-top: calc(60 / var(--base-width) * 100vw);
}
.pt-60d {
  padding-top: calc(60 / var(--base-width) * 100vw);
}
.mt-56d {
  margin-top: calc(56 / var(--base-width) * 100vw);
}
.pl-48d {
  padding-left: calc(48 / var(--base-width) * 100vw);
}

.mt-48d {
  margin-top: calc(48 / var(--base-width) * 100vw);
}
.px-45d {
  padding-left: calc(45 / var(--base-width) * 100vw);
  padding-right: calc(45 / var(--base-width) * 100vw);
}
.mt-42d {
  margin-top: calc(42 / var(--base-width) * 100vw);
}
.mt-40d {
  margin-top: calc(40 / var(--base-width) * 100vw);
}
.mt-36d {
  margin-top: calc(36 / var(--base-width) * 100vw);
}
.mt-32d {
  margin-top: calc(32 / var(--base-width) * 100vw);
}
.pt-32d {
  padding-top: calc(32 / var(--base-width) * 100vw);
}
.mt-30d {
  margin-top: calc(30 / var(--base-width) * 100vw);
}
.pl-30d {
  padding-left: calc(30 / var(--base-width) * 100vw);
}
.p-26d {
  padding: calc(26 / var(--base-width) * 100vw);
}

.px-26d {
  /* 左右兩邊padding + dynamic */
  padding-left: calc(26 / var(--base-width) * 100vw);
  padding-right: calc(26 / var(--base-width) * 100vw);
}

.pt-26d {
  padding-top: calc(26 / var(--base-width) * 100vw);
}

.pb-26d {
  padding-bottom: calc(26 / var(--base-width) * 100vw);
}

.pl-26d {
  padding-left: calc(26 / var(--base-width) * 100vw);
}

.pr-26d {
  padding-right: calc(26 / var(--base-width) * 100vw);
}

.px-26 {
  /* 左右兩邊padding */
  padding-left: 26px;
  padding-right: 26px;
}

.mt-26d {
  margin-top: calc(26 / var(--base-width) * 100vw);
}
.mb-26d {
  margin-bottom: calc(26 / var(--base-width) * 100vw);
}

.mx-26d {
  /* 左右兩邊margin + dynamic */
  margin-left: calc(26 / var(--base-width) * 100vw) !important;
  margin-right: calc(26 / var(--base-width) * 100vw) !important;
}

.pb-24d {
  padding-bottom: calc(24 / var(--base-width) * 100vw);
}

.py-20d {
  padding-top: calc(20 / var(--base-width) * 100vw);
  padding-bottom: calc(20 / var(--base-width) * 100vw);
}
.pt-20d {
  padding-top: calc(20 / var(--base-width) * 100vw);
}
.mt-20d {
  margin-top: calc(20 / var(--base-width) * 100vw);
}
.ml-20d {
  margin-left: calc(20 / var(--base-width) * 100vw);
}

.mt-18d {
  margin-top: calc(18 / var(--base-width) * 100vw);
}

.px-16d {
  /* 左右兩邊padding + dynamic */
  padding-left: calc(16 / var(--base-width) * 100vw);
  padding-right: calc(16 / var(--base-width) * 100vw);
}

.pt-16d {
  padding-top: calc(16 / var(--base-width) * 100vw);
}

.pr-16d {
  padding-right: calc(16 / var(--base-width) * 100vw);
}

.pb-16d {
  padding-bottom: calc(16 / var(--base-width) * 100vw);
}

.mb-16d {
  margin-bottom: calc(16 / var(--base-width) * 100vw);
}
.mt-14d {
  margin-top: calc(14 / var(--base-width) * 100vw);
}
.mt-16d {
  margin-top: calc(16 / var(--base-width) * 100vw);
}
.ml-16d {
  margin-left: calc(16 / var(--base-width) * 100vw);
}
.mr-16d {
  margin-right: calc(16 / var(--base-width) * 100vw);
}

.px-12d {
  padding-left: calc(12 / var(--base-width) * 100vw);
  padding-right: calc(12 / var(--base-width) * 100vw);
}
.pt-12d {
  padding-top: calc(12 / var(--base-width) * 100vw);
}
.pb-12d {
  padding-bottom: calc(12 / var(--base-width) * 100vw);
}
.pl-12d {
  padding-left: calc(12 / var(--base-width) * 100vw);
}
.pr-12d {
  padding-right: calc(12 / var(--base-width) * 100vw);
}

.mt-12d {
  margin-top: calc(12 / var(--base-width) * 100vw);
}
.mr-12d {
  margin-right: calc(12 / var(--base-width) * 100vw);
}
.mb-12d {
  margin-bottom: calc(12 / var(--base-width) * 100vw);
}
.ml-12d {
  margin-left: calc(12 / var(--base-width) * 100vw);
}

.px-10d {
  padding-left: calc(10 / var(--base-width) * 100vw);
  padding-right: calc(10 / var(--base-width) * 100vw);
}
.pt-10d {
  padding-top: calc(10 / var(--base-width) * 100vw);
}
.pr-10d {
  padding-right: calc(10 / var(--base-width) * 100vw);
}
.pb-10d {
  padding-bottom: calc(10 / var(--base-width) * 100vw);
}
.pl-10d {
  padding-left: calc(10 / var(--base-width) * 100vw);
}
.p-10d {
  padding: calc(10 / var(--base-width) * 100vw);
}

.mt-10d {
  margin-top: calc(10 / var(--base-width) * 100vw);
}
.mr-10d {
  margin-right: calc(10 / var(--base-width) * 100vw);
}
.mb-10d {
  margin-bottom: calc(10 / var(--base-width) * 100vw);
}
.ml-10d {
  margin-left: calc(10 / var(--base-width) * 100vw);
}

.pt-8d {
  padding-top: calc(8 / var(--base-width) * 100vw);
}
.pr-8d {
  padding-right: calc(8 / var(--base-width) * 100vw);
}
.pb-8d {
  padding-bottom: calc(8 / var(--base-width) * 100vw);
}
.pl-8d {
  padding-left: calc(8 / var(--base-width) * 100vw);
}

.mt-8d {
  margin-top: calc(8 / var(--base-width) * 100vw);
}
.mr-8d {
  margin-right: calc(8 / var(--base-width) * 100vw);
}
.mb-8d {
  margin-bottom: calc(8 / var(--base-width) * 100vw);
}
.ml-8d {
  margin-left: calc(8 / var(--base-width) * 100vw);
}

.pt-6d {
  padding-top: calc(6 / var(--base-width) * 100vw);
}
.pr-6d {
  padding-right: calc(6 / var(--base-width) * 100vw);
}
.pb-6d {
  padding-bottom: calc(6 / var(--base-width) * 100vw);
}
.pl-6d {
  padding-left: calc(6 / var(--base-width) * 100vw);
}

.mt-6d {
  margin-top: calc(6 / var(--base-width) * 100vw);
}
.mr-6d {
  margin-right: calc(6 / var(--base-width) * 100vw);
}
.mb-6d {
  margin-bottom: calc(6 / var(--base-width) * 100vw);
}
.ml-6d {
  margin-left: calc(6 / var(--base-width) * 100vw);
}

.pt-5d {
  padding-top: calc(5 / var(--base-width) * 100vw);
}
.pr-5d {
  padding-right: calc(5 / var(--base-width) * 100vw);
}
.pb-5d {
  padding-bottom: calc(5 / var(--base-width) * 100vw);
}
.pl-5d {
  padding-left: calc(5 / var(--base-width) * 100vw);
}
.py-5d {
  padding-top: calc(5 / var(--base-width) * 100vw);
  padding-bottom: calc(5 / var(--base-width) * 100vw);
}

.mt-5d {
  margin-top: calc(5 / var(--base-width) * 100vw);
}
.mr-5d {
  margin-right: calc(5 / var(--base-width) * 100vw);
}
.mb-5d {
  margin-bottom: calc(5 / var(--base-width) * 100vw);
}
.ml-5d {
  margin-left: calc(5 / var(--base-width) * 100vw);
}
.my-5d {
  margin-top: calc(5 / var(--base-width) * 100vw);
  margin-bottom: calc(5 / var(--base-width) * 100vw);
}
.mt-2d {
  margin-top: calc(2 / var(--base-width) * 100vw);
  margin-bottom: calc(2 / var(--base-width) * 100vw);
}

/******************************************************************************/
/*  Size class */
/******************************************************************************/
.size-212d {
  width: calc(212 * var(--width-ratio));
  height: calc(212 * var(--width-ratio));
}
.size-130d {
  width: calc(130 * var(--width-ratio));
  height: calc(130 * var(--width-ratio));
}
.size-100d {
  width: calc(100 * var(--width-ratio));
  height: calc(100 * var(--width-ratio));
}
.size-98d {
  width: calc(98 * var(--width-ratio));
  height: calc(98 * var(--width-ratio));
}
.size-86d {
  width: calc(86 * var(--width-ratio));
  height: calc(86 * var(--width-ratio));
}
.size-70d {
  width: calc(70 * var(--width-ratio));
  height: calc(70 * var(--width-ratio));
}
.size-68d {
  width: calc(68 * var(--width-ratio));
  height: calc(68 * var(--width-ratio));
}
.size-60d {
  width: calc(60 * var(--width-ratio));
  height: calc(60 * var(--width-ratio));
}
.size-56d {
  width: calc(56 * var(--width-ratio));
  height: calc(56 * var(--width-ratio));
}
.size-54d {
  width: calc(54 * var(--width-ratio));
  height: calc(54 * var(--width-ratio));
}
.size-50d {
  width: calc(50 * var(--width-ratio));
  height: calc(50 * var(--width-ratio));
}
.size-48d {
  width: calc(48 * var(--width-ratio));
  height: calc(48 * var(--width-ratio));
}
.size-46d {
  width: calc(46 * var(--width-ratio));
  height: calc(46 * var(--width-ratio));
}
.size-43d {
  width: calc(43 * var(--width-ratio));
  height: calc(43 * var(--width-ratio));
}
.size-42d {
  width: calc(42 * var(--width-ratio));
  height: calc(42 * var(--width-ratio));
}
.size-40d {
  width: calc(40 * var(--width-ratio));
  height: calc(40 * var(--width-ratio));
}
.size-38d {
  width: calc(38 * var(--width-ratio));
  height: calc(38 * var(--width-ratio));
}
.size-36d {
  width: calc(36 * var(--width-ratio));
  height: calc(36 * var(--width-ratio));
}
.size-34d {
  width: calc(34 * var(--width-ratio));
  height: calc(34 * var(--width-ratio));
}
.size-30d {
  width: calc(30 * var(--width-ratio));
  height: calc(30 * var(--width-ratio));
}
.size-26d {
  width: calc(26 * var(--width-ratio));
  height: calc(26 * var(--width-ratio));
}
.size-24d {
  width: calc(24 * var(--width-ratio));
  height: calc(24 * var(--width-ratio));
}
.size-22d {
  width: calc(22 * var(--width-ratio));
  height: calc(22 * var(--width-ratio));
}
.size-20d {
  width: calc(20 * var(--width-ratio));
  height: calc(20 * var(--width-ratio));
}
.size-18d {
  width: calc(18 * var(--width-ratio));
  height: calc(18 * var(--width-ratio));
}
.size-16d {
  width: calc(16 * var(--width-ratio));
  height: calc(16 * var(--width-ratio));
}
.size-12d {
  width: calc(12 * var(--width-ratio));
  height: calc(12 * var(--width-ratio));
}
.size-10d {
  width: calc(10 * var(--width-ratio));
  height: calc(10 * var(--width-ratio));
}
.size-8d {
  width: calc(8 * var(--width-ratio));
  height: calc(8 * var(--width-ratio));
}

@media only screen and (min-width: 768px) {
  .md-p-26d {
    padding: calc(26 / var(--base-width) * 100vw);
  }
}

// @media screen and (min-width: 375px) and (max-width: 414px) {
//   // 如果使用者之視窗寬度介於 768px ~ 979px，將會再載入這裡的 CSS。
//   .H2 {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 24px;
//     line-height: 26px;
//     color: var(--ion-color-dark);
//   }

//   .H2_M {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 24px;
//     line-height: 26px;
//     color: var(--ion-color-dark);
//   }

//   .H2_B {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 700;
//     font-size: 24px;
//     line-height: 26px;
//     color: var(--ion-color-dark);
//   }

//   .H3_B {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 700;
//     font-size: 22px;
//     line-height: 26px;
//     color: var(--ion-color-dark-tint);
//   }

//   .H3_M {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 22px;
//     line-height: 26px;
//     color: var(--ion-color-dark);
//   }

//   .H4_B {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 700;
//     font-size: 20px;
//     line-height: 26px;
//     color: var(--ion-color-dark);
//   }

//   .H4_M {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 20px;
//     line-height: 26px;
//     color: var(--ion-color-dark);
//   }

//   .H5 {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 300;
//     font-size: 18px;
//     line-height: 26px;
//     color: var(--ion-color-dark);
//   }

//   .H5_B {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 700;
//     font-size: 18px;
//     line-height: 26px;
//     color: var(--ion-color-dark);
//   }

//   .H5_M {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 18px;
//     line-height: 26px;
//     color: var(--ion-color-dark);
//   }

//   .H6 {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 300;
//     font-size: 16px;
//     line-height: 28px;
//     color: var(--ion-color-dark);
//   }

//   .H6_B {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 700;
//     font-size: 16px;
//     line-height: 26px;
//     color: var(--ion-color-dark);
//   }

//   .H6_M {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 16px;
//     line-height: 26px;
//     color: var(--ion-color-dark);
//   }

//   .Subtitle1 {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 15px;
//     line-height: 22px;
//     color: var(--ion-color-dark);
//   }

//   .Subtitle1_B {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 700;
//     font-size: 15px;
//     line-height: 22px;
//     color: var(--ion-color-dark);
//   }

//   .Subtitle1_M {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 15px;
//     line-height: 22px;
//     color: var(--ion-color-dark);
//   }

//   .Subtitle2 {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 22px;
//     color: var(--ion-color-dark);
//   }

//   .Subtitle2_B {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 700;
//     font-size: 14px;
//     line-height: 22px;
//     color: var(--ion-color-dark);
//   }

//   .Subtitle2_M {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 22px;
//     color: var(--ion-color-dark);
//   }

//   .Caption {
//     font-family: "Noto Sans";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 12px;
//     line-height: 16px;
//     color: var(--ion-color-dark);
//   }

//   .Caption_B {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 700;
//     font-size: 12px;
//     line-height: 16px;
//     color: var(--ion-color-dark);
//   }

//   .Caption_M {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 12px;
//     line-height: 16px;
//     color: var(--ion-color-dark);
//   }

//   .Overline {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 10px;
//     line-height: 10px;
//     color: var(--ion-color-dark);
//   }

//   .Overline_B {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 700;
//     font-size: 10px;
//     line-height: 10px;
//     color: var(--ion-color-dark);
//   }

//   /******************************************************************************/
//   /*  Padding , Margin class */
//   /******************************************************************************/
//   .mt-60d {
//     margin-top: 60px;
//   }

//   .pl-48d {
//     padding-left: 48px;
//   }

//   .mt-48d {
//     margin-top: 48px;
//   }
//   .px-45d {
//     padding-left: 45px;
//     padding-right: 45px;
//   }
//   .mt-42d {
//     margin-top: 42px;
//   }
//   .mt-40d {
//     margin-top: 40px;
//   }
//   .mt-30d {
//     margin-top: 30px;
//   }
//   .pl-30d {
//     padding-left: 30px;
//   }
//   .p-26d {
//     padding: 26px;
//   }

//   .px-26d {
//     /* 左右兩邊padding + dynamic */
//     padding-left: 26px;
//     padding-right: 26px;
//   }

//   .pt-26d {
//     padding-top: 26px;
//   }

//   .pb-26d {
//     padding-bottom: 26px;
//   }

//   .pl-26d {
//     padding-left: 26px;
//   }

//   .pr-26d {
//     padding-right: 26px;
//   }

//   .px-26 {
//     /* 左右兩邊padding */
//     padding-left: 26px;
//     padding-right: 26px;
//   }

//   .mt-26d {
//     margin-top: 26px;
//   }
//   .mb-26d {
//     margin-bottom: 26px;
//   }

//   .mx-26d {
//     /* 左右兩邊margin + dynamic */
//     margin-left: 26px !important;
//     margin-right: 26px !important;
//   }

//   .pb-24d {
//     padding-bottom: 24px;
//   }

//   .py-20d {
//     padding-top: 20px;
//     padding-bottom: 20px;
//   }
//   .pt-20d {
//     padding-top: 20px;
//   }
//   .mt-20d {
//     margin-top: 20px;
//   }
//   .ml-20d {
//     margin-left: 20px;
//   }

//   .mt-18d {
//     margin-top: 18px;
//   }

//   .px-16d {
//     /* 左右兩邊padding + dynamic */
//     padding-left: 16px;
//     padding-right: 16px;
//   }

//   .pt-16d {
//     padding-top: 16px;
//   }

//   .pb-16d {
//     padding-bottom: 16px;
//   }

//   .mb-16d {
//     margin-bottom: 16px;
//   }
//   .mt-16d {
//     margin-top: 16px;
//   }
//   .ml-16d {
//     margin-left: 16px;
//   }
//   .mt-14d {
//     margin-top: 14px;
//   }
//   .px-12d {
//     padding-left: 12px;
//     padding-right: 12px;
//   }
//   .pt-12d {
//     padding-top: 12px;
//   }
//   .pb-12d {
//     padding-bottom: 12px;
//   }
//   .pl-12d {
//     padding-left: 12px;
//   }
//   .pr-12d {
//     padding-right: 12px;
//   }

//   .mt-12d {
//     margin-top: 12px;
//   }
//   .mr-12d {
//     margin-right: 12px;
//   }
//   .mb-12d {
//     margin-bottom: 12px;
//   }
//   .ml-12d {
//     margin-left: 12px;
//   }

//   .px-10d {
//     padding-left: 10px;
//     padding-right: 10px;
//   }
//   .pt-10d {
//     padding-top: 10px;
//   }
//   .pr-10d {
//     padding-right: 10px;
//   }
//   .pb-10d {
//     padding-bottom: 10px;
//   }
//   .pl-10d {
//     padding-left: 10px;
//   }

//   .mt-10d {
//     margin-top: 10px;
//   }
//   .mr-10d {
//     margin-right: 10px;
//   }
//   .mb-10d {
//     margin-bottom: 10px;
//   }
//   .ml-10d {
//     margin-left: 10px;
//   }

//   .pt-8d {
//     padding-top: 8px;
//   }
//   .pr-8d {
//     padding-right: 8px;
//   }
//   .pb-8d {
//     padding-bottom: 8px;
//   }
//   .pl-8d {
//     padding-left: 8px;
//   }

//   .mt-8d {
//     margin-top: 8px;
//   }
//   .mr-8d {
//     margin-right: 8px;
//   }
//   .mb-8d {
//     margin-bottom: 8px;
//   }
//   .ml-8d {
//     margin-left: 8px;
//   }

//   .pt-6d {
//     padding-top: 6px;
//   }
//   .pr-6d {
//     padding-right: 6px;
//   }
//   .pb-6d {
//     padding-bottom: 6px;
//   }
//   .pl-6d {
//     padding-left: 6px;
//   }

//   .mt-6d {
//     margin-top: 6px;
//   }
//   .mr-6d {
//     margin-right: 6px;
//   }
//   .mb-6d {
//     margin-bottom: 6px;
//   }
//   .ml-6d {
//     margin-left: 6px;
//   }

//   .pt-5d {
//     padding-top: 5px;
//   }
//   .pr-5d {
//     padding-right: 5px;
//   }
//   .pb-5d {
//     padding-bottom: 5px;
//   }
//   .pl-5d {
//     padding-left: 5px;
//   }
//   .py-5d {
//     padding-top: 5px;
//     padding-bottom: 5px;
//   }

//   .mt-5d {
//     margin-top: 5px;
//   }
//   .mr-5d {
//     margin-right: 5px;
//   }
//   .mb-5d {
//     margin-bottom: 5px;
//   }
//   .ml-5d {
//     margin-left: 5px;
//   }
//   .my-5d {
//     margin-top: 5px;
//     margin-bottom: 5px;
//   }
//   .mt-2d {
//     margin-top: 2px;
//     margin-bottom: 2px;
//   }

//   /******************************************************************************/
//   /*  Size class */
//   /******************************************************************************/
//   .size-212d {
//     width: 212px;
//     height: 212px;
//   }
//   .size-130d {
//     width: 130px;
//     height: 130px;
//   }
//   .size-100d {
//     width: 100px;
//     height: 100px;
//   }
//   .size-98d {
//     width: 98px;
//     height: 98px;
//   }
//   .size-70d {
//     width: 70px;
//     height: 70px;
//   }
//   .size-68d {
//     width: 68px;
//     height: 68px;
//   }
//   .size-56d {
//     width: 56px;
//     height: 56px;
//   }
//   .size-54d {
//     width: 54px;
//     height: 54px;
//   }
//   .size-50d {
//     width: 50px;
//     height: 50px;
//   }
//   .size-48d {
//     width: 48px;
//     height: 48px;
//   }
//   .size-46d {
//     width: 46px;
//     height: 46px;
//   }
//   .size-43d {
//     width: 43px;
//     height: 43px;
//   }
//   .size-42d {
//     width: 42px;
//     height: 42px;
//   }
//   .size-40d {
//     width: 40px;
//     height: 40px;
//   }
//   .size-38d {
//     width: 38px;
//     height: 38px;
//   }
//   .size-36d {
//     width: 36px;
//     height: 36px;
//   }
//   .size-34d {
//     width: 34px;
//     height: 34px;
//   }
//   .size-30d {
//     width: 30px;
//     height: 30px;
//   }
//   .size-26d {
//     width: 26px;
//     height: 26px;
//   }
//   .size-24d {
//     width: 24px;
//     height: 24px;
//   }
//   .size-22d {
//     width: 22px;
//     height: 22px;
//   }
//   .size-20d {
//     width: 20px;
//     height: 20px;
//   }
//   .size-18d {
//     width: 18px;
//     height: 18px;
//   }
//   .size-16d {
//     width: 16px;
//     height: 16px;
//   }
//   .size-12d {
//     width: 12px;
//     height: 12px;
//   }
//   .size-10d {
//     width: 10px;
//     height: 10px;
//   }
//   .size-8d {
//     width: 8px;
//     height: 8px;
//   }
// }
